'use strict';

class UserWidget extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpened: false,
            token: userWidgetData.token,
            name: userWidgetData.name,
            count: userWidgetData.count,
            hasCompany: userWidgetData.hasCompany,
        }
        this.handleClick = this.handleClick.bind(this);
        this.update = this.update.bind(this);
    }

    componentDidMount() {
        const {token, name, count, hasCompany, isOpened} = this.state;

        if(token) {
            storageSet('userToken', token);
            storageSet('userWidgetData', {token, name, count, hasCompany});
        }

        storageListen('userWidgetData', data => {
            this.setState(data ? data : {
                isOpened: false,
                token: null,
                name: null,
                count: 0,
                hasCompany: false,
            })
        });

        listenEvent('toggleSideBeard', data => {
            let isOpened = !this.state.isOpened;
            if(data && typeof data.isOpened !== "undefined") {
                isOpened = data.isOpened
            }
            this.setOpened(isOpened);
        })

        if (feature_enabled('FEATURE_WEBSOCKETS')) {
            window.Echo.private('noty.' + window.userId)
                .listen('noty-saved', data => {
                    this.setState({count: data.count})
                    raiseEvent('toggleSideBeard', {fetch: true, isOpened});
                })
        } else {
            setInterval(this.update, 15000)
        }
    }

    handleClick(e) {
        e.preventDefault();
        raiseEvent('toggleSideBeard');
    }

    setOpened(isOpened) {
        this.setState({isOpened})
    }

    update() {
        const {token, isOpened, count} = this.state;
        if(token) accountAction.fetchNotyCnt().then(response => {
            if(count !== response.count) {
                this.setState({count: response.count})
                raiseEvent('toggleSideBeard', {fetch: true, isOpened});
            }
        })
    }

    render() {
        const {isOpened, token, name, count} = this.state;

        if(token === null) return <div className="drop login-drop">
            <a href="#reactAuthModal" className="drop-link" data-fancybox>{tMsg2('menu.login')}</a>
        </div>

        return <div className="drop logined-drop">
            <a href="#" className={clsx('drop-link logined-link', {active: isOpened})} onClick={this.handleClick}>
                {count > 0 && <i className="noti-count">{count}</i>}
                <span>{name}</span>
            </a>
        </div>
    }
}

const domContainer = document.querySelector('#reactUserWidget');
const root = ReactDOM.createRoot(domContainer);
root.render(React.createElement(UserWidget));
